<script>
import { useLinkObject } from '@/pages/Dashboard/composables/LinkObject';
import WfStatusPill from '@/pages/Workflows/components/WfStatusPill.vue';
import { useDashboardStore } from '@/stores/dashboard-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { useWorkflowsStore } from '@/stores/workflows-store';
import { mapState } from 'pinia';

export default {
  name: 'StateRenderer',
  components: {
    WfStatusPill,
  },
  setup() {
    return {
      harbourStore: useHarbourStore(),
      dashboardStore: useDashboardStore(),
      workflowsStore: useWorkflowsStore(),
    };
  },
  data() {
    return {
      linkObject: null,
      isPendingAction: false,
      textMap: {
        pending: 'Awaiting approval',
        sent_back: 'Awaiting review',
        approved: 'Approved',
      },
    };
  },
  created() {
    this.field = this.params.colDef.field;
    this.linkObject = useLinkObject(this.params.data);
  },
  beforeUnmount() {
    this.linkObject = null;
  },
  computed: {
    linkStatuses() {
      return this.dashboardStore.getLinkStatuses(this.params.data);
    },

    mainStatusClassAndText() {
      let status = this.linkStatuses.main;

      const statusMap = {
        completed: {
          className: 'completion-cell-completed',
          text: 'Completed',
        },
        'completed-emails': {
          className: 'completion-cell-completed',
          text: `Completed (${this.linkObject.submissions.length})`,
        },
        sent: {
          className: 'is-sent',
          text: 'Sent',
        },
        'pending-signed': {
          className: 'completion-cell-pending',
          // like "Pending (1/3)""
          text: `Pending (${this.linkObject.emailRecipientsCompleted.length}/${this.emailRecipientsWithoutReassigned.length})`,
        },
        'pending-unsigned': {
          className: 'completion-cell-pending',
          text: 'Pending (0)',
        },
      };

      return statusMap[status];
    },

    mainStatusClass() {
      return this.mainStatusClassAndText.className;
    },

    mainStatusText() {
      return this.mainStatusClassAndText.text;
    },

    getClass() {
      if (this.linkObject.getCompletionState === 'COMPLETED') return 'completion-cell-completed';
      if (this.getApprovers?.length) return 'pending-approval';
      else return 'completion-cell-pending';
    },
    getApprovers() {
      const status = this.linkObject?.workflowsStatus;
      if (!status || !Array.isArray(status) || status.length === 0) return;

      const statusMap = {
        start: 'Pending approval',
        'load-magic-link': 'Opened approval link',
        'approval-hit': 'Approved',
        'send-back-hit': 'Sent back for changes',
      };

      if (status[0].state === 'completed') return;
      if (!('approval_status' in status[0]) || status[0].approval_status?.length === 0) return;

      const approvers = [];
      status[0].approval_status?.forEach((approver) => {
        approvers.push({
          name: approver.event_user_name,
          email: approver.event_user_email,
          eventTime: approver.event_time,
          status: statusMap[approver.event_type],
        });
      });

      return approvers;
    },

    activeApproval() {
      const activeApprovals = this.linkObject?.workflowsStatus.filter((wf) => {
        return wf.status?.details?.approval_status && wf.status.state !== 'completed';
      });

      if (activeApprovals.length === 1) {
        return activeApprovals[0];
      }

      return null;
    },
    completedApproval() {
      const workflows = this.linkObject?.workflowsStatus;
      for (let wf of workflows) {
        if (!wf.snapshot) continue;
        if (this.isWorkflowApproved(wf.snapshot)) return true;
      }
      return false;
    },
    ...mapState(useHarbourStore, ['contextDict']),
    ...mapState(useWorkflowsStore, ['isWorkflowApproved']),
    getUsers() {
      const approval = this.activeApproval;
      if (approval.status.stage === 'sent_back') {
        if (approval.status.details.creator_email) {
          return [
            {
              email: approval.status.details.creator_email,
              name: approval.status.details.creator_name,
            },
          ];
        }
        return null;
      } else {
        return approval.status.details.approvers;
      }
    },
    isWaitingOnMe() {
      const currentUserEmail = this.contextDict?.systememail;
      const creatorUser = {
        email: this.activeApproval.status.details.creator_email,
        name: this.activeApproval.status.details.creator_name,
      };
      const users =
        this.activeApproval?.status?.stage === 'sent_back'
          ? [creatorUser]
          : this.activeApproval.status.details.approvers;
      if (!users || users.length === 0) return false;

      const isPending = users.some((user) => user.email === currentUserEmail);
      return isPending;
    },
    emailRecipientsWithoutReassigned() {
      return this.linkObject?.emailRecipients?.filter((recipient) => !recipient.reassigned) || 0;
    },
    reviewButton() {
      return {
        text: 'Review',
        styleType: 'primary',
        style: {
          fontSize: '13px',
        },
        loading: this.isPendingAction,
        disabled: this.isPendingAction,
      };
    },
  },
  methods: {
    async handleRedirect() {
      this.isPendingAction = true;
      if (this.activeApproval) {
        await this.workflowsStore.redirectToMyLink(this.activeApproval);
      } else {
        this.dashboardStore.gridApi?.refreshCells();
      }
      this.isPendingAction = false;
    },
  },
};
</script>

<template>
  <div class="state-wrapper">
    <!-- Approval statuses -->
    <div v-if="activeApproval">
      <WfStatusPill
        v-if="linkStatuses.secondary === 'approval-waiting-other'"
        style="font-size: 12px"
        :users="getUsers"
        :display-text="textMap[activeApproval.status.stage]"
        :stage="activeApproval.status.stage" />
      <HrbrButton
        v-if="linkStatuses.secondary === 'approval-waiting-me'"
        :button="reviewButton"
        @click="handleRedirect" />
    </div>

    <div class="non-approval-state" :class="getClass" v-else>
      <div v-if="linkStatuses.secondary === 'approved'" class="pending-approval approved-cell">
        Approved
      </div>
      <div :class="mainStatusClass">
        {{ mainStatusText }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.approver-info {
  display: flex;
}
.approver-date {
  font-size: 11px;
  color: #999;
}
.approver-row {
  font-size: 13px;
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
  padding: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #dadada;
}

.approver-name i,
.approver-status i {
  margin-right: 10px;
}
.approver-name {
  font-weight: 600;
  white-space: nowrap;
  margin-right: 5px;
}
.approver-email {
  color: #999;
}
.state-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.completion-cell-completed,
.completion-cell-pending,
.pending-approval,
.is-sent,
.approved-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  min-width: 50px;
  border-radius: 16px;
  padding: 3px 12px;
  font-size: 12px;
  color: #333;
}
.completion-cell-completed {
  background-color: #9ccdcd;
}
.completion-cell-pending {
  background-color: #9fd3f0;
}
.pending-approval {
  background-color: #f2df9d;
  color: #333;
}
.is-sent {
  background-color: #9ab9dc;
}
.approved {
  color: green;
}
.pending {
  color: #ab942f;
}
.non-approval-state {
  display: flex;
  flex-direction: column;
  background-color: transparent;
}
.approved-cell {
  min-width: unset;
  padding: 2px 8px;
  margin-bottom: 5px;
  background-color: #cde6e6;
  font-size: 8px;
  line-height: 18px;
}
</style>