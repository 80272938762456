<script>
import { DraftTypes } from '@/domain/drafts/draft';
import { useHarbourStore } from '@/stores/harbour-store';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';

export default {
  name: 'DraftsTitleRenderer',

  data() {
    return {
      newDraftName: '',
      isEditDraftName: false,
    };
  },

  setup() {
    const harbourStore = useHarbourStore();
    const draftsStore = useDraftsStore();
    return {
      harbourStore,
      draftsStore,
    };
  },

  computed: {
    draft() {
      return this.params.data;
    },
    draftName() {
      return this.draft.name;
    },
    isPdfType() {
      return this.draft.type === DraftTypes.Pdf;
    },
    isDocxType() {
      return this.draft.type === DraftTypes.Docx;
    },
    isUserOwner() {
      return !this.draft.isShared;
    },
    isSuperdoc() {
      return this.params.data.type === DraftTypes.SuperDoc;
    },
    isUserCanRename() {
      if (this.isSuperdoc) return true;
      const systemEmail = this.harbourStore.contextDict.systememail;
      const isWriter = this.draft.writer.includes(systemEmail);
      const isOrgAdmin = this.draftsStore.checkUserOrgAdminPermission(this.draft.orgId);
      return this.isUserOwner || isWriter || isOrgAdmin;
    },
  },

  methods: {
    onDraftOpenClick() {
      this.draftsStore.openDraftDocument({
        draft: this.draft,
        addUrlParams: true,
        parentComponent: this,
      });
    },

    onDraftNameClick() {
      if (!this.isUserCanRename) return;
      const { nameInput } = this.$refs;
      this.isEditDraftName = true;
      this.newDraftName = this.draftName;
      this.$nextTick(() => nameInput.focus());
    },

    onNameInputBlur() {
      const { newDraftName } = this;
      this.isEditDraftName = false;
      this.newDraftName = '';
      this.draftsStore.renameDraftWithNotify(this.draft.id, newDraftName);
      this.draftsStore.agGridApi?.redrawRows({ rowNodes: [this.params.node] });
    },
  },
};
</script>

<template>
  <div
    class="draft-name-column"
    :class="{'draft-name-column--editing': isEditDraftName}">
    <div class="draft-name-column__title">
      <div class="draft-name-column__title-icon">
        <i class="fa-light fa-file-pdf" v-if="isPdfType"></i>
        <i class="fa-light fa-file-word" v-else-if="isDocxType"></i>
        <i class="fa-light fa-stars" v-else-if="isSuperdoc"></i>
      </div>
      <div class="draft-name-column__title-name">
        <div
          class="draft-name-column__title-name-text"
          @click="onDraftNameClick"
          v-show="!isEditDraftName"
          :title="draftName">
          {{ draftName }}
        </div>
        <input
          class="draft-name-column__title-name-input"
          v-model="newDraftName"
          v-show="isEditDraftName"
          ref="nameInput"
          type="text"
          @blur="onNameInputBlur"
          @keyup.enter="$event.target.blur()" />
      </div>
    </div>

    <div class="draft-name-column__open-btn" @click="onDraftOpenClick">
      <div class="draft-name-column__open-btn-text">Open</div>
    </div>
  </div>
</template>

<style scoped lang="postcss">
.draft-name-column {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  min-height: 49px;
  padding: 7px 8px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid #DBDBDB;
  transition: all 0.2s;

  &:hover,
  &--editing {
    border-color: #1355FF;
    box-shadow: 0px 4px 4px 0px rgba(19, 85, 255, 0.15);
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-grow: 1;
    color: #333;
    word-break: normal;
    min-width: 0;
  }

  &__title-icon {
    display: flex;
    flex-shrink: 0;
    font-size: 20px;
    position: relative;
    top: -2px;
  }

  &__title-name {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    overflow: hidden;
    flex: 1;
  }

  &__title-name-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: text;
  }

  &__title-name-input {
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
    color: inherit;
    display: block;
    width: 100%;
    height: 100%;
    min-height: 20px;
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    appearance: none;
  }

  &__open-btn {
    font-family: inherit;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.1;
    color: #333;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px 12px;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    cursor: pointer;
    background: none;
    user-select: none;
    flex-shrink: 0;
    transition: all 0.2s;

    &:hover {
      background: #DBDBDB;
    }
  }
}
</style>
