<script setup>
import { useDashboardStore } from '@/stores/dashboard-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import HrbrSidebarPaneHeader from './HrbrSidebarPaneHeader.vue';
import HrbrSidebarPaneListItem from './HrbrSidebarPaneListItem.vue';
import HrbrSidebarPaneSection from './HrbrSidebarPaneSection.vue';
import { useFilterItems } from './composables/filter-items';
import { useSidebarStore } from './stores/sidebar-store';

const sidebarStore = useSidebarStore();
const harbourStore = useHarbourStore();
const dashboardStore = useDashboardStore();

const router = useRouter();

const {
  myPendingContracts,
  mySignedAgreements,
} = storeToRefs(harbourStore);

const { isCompleted } = dashboardStore;
const { 
  mylinksCompleteState,
  totalLinks,
  totalCompleted,
  totalPending,
  totalAwaitingMyReview,
  totalSignedByMe,
} = storeToRefs(dashboardStore);

const handlePaneChange = (item) => {
  if (!item.action) return;
  mylinksCompleteState.value = item.param;
  item.action();
  sidebarStore.setDashboardPaneActive(item.id);
  dashboardStore.currentFilterTitle = item.title;
  dashboardStore.numDisplayedLinks = dashboardStore.gridApi?.getDisplayedRowCount() || 0;
  setTimeout(() => {
    const keys = [
        'totalLinks',
        'totalCompleted',
        'totalPending',
        'totalAwaitingMyReview',
        'totalSignedByMe',
      ]
      if (!keys.find((key) => !dashboardStore[key])) return;
      dashboardStore.updateCounts();
  }, 0);
};

const handleDashboardFilterChange = (param) => {
  const firstFilter = dashboardStore.mylinksCompleteState.toLowerCase();
  router.push({ name: 'dashboard', query: { filter: firstFilter } });
  const acceptableFilters = ['completed', 'pending', 'all'];
  const filter = router.currentRoute?.query?.filter?.toUpperCase();

  if (acceptableFilters.includes(filter?.toLowerCase())) {
    dashboardStore.mylinksCompleteState = filter;
    dashboardStore.filterByState();
  }
};


const mainItems = ref([
  {
    id: 'all-contracts',
    title: 'All',
    icon: '<i class="fa-light fa-file-contract"></i>',
    action: () => handleDashboardFilterChange('ALL'),
    param :'ALL',
    count: computed(() => totalLinks.value),
  },
  {
    id: 'pending-others',
    title: 'Pending',
    icon: '<i class="fa-light fa-file-export"></i>',
    action: () => handleDashboardFilterChange('PENDING'),
    param: 'PENDING',
    count: computed(() => totalPending.value),
  },
  {
    id: 'fully-completed',
    title: 'Completed',
    icon: '<i class="fa-light fa-file-check"></i>',
    action: () => handleDashboardFilterChange('COMPLETED'),
    param: 'COMPLETED',
    count: computed(() => totalCompleted.value),
  },
]);

const personalItems = ref([
  {
    id: 'awaiting-my-review',
    title: 'Awaiting my review',
    icon: '<i class="fa-light fa-file-signature"></i>',
    action: () => {
      router.push({ name: 'awaiting-my-review' });
    },
    loading: computed(() => !harbourStore.isMySignedAgreementsReady),
    count: computed(() => totalAwaitingMyReview.value),
  },
  {
    id: 'signed-by-me',
    title: 'Signed by me',
    icon: '<i class="fa-light fa-file-user"></i>',
    loading: computed(() => !harbourStore.isMySignedAgreementsReady),
    action: () => {
      router.push({ name: 'signed-by-me' });
    },
    count: computed(() => totalSignedByMe.value),
  },
]);

const { filteredItems: filteredMainItems } = useFilterItems(mainItems);
const { filteredItems: filteredPersonalItems } = useFilterItems(personalItems);
</script>

<template>
  <div class="hrbr-sidebar-pane-tab hrbr-sidebar-pane-dashboard">
    <div class="hrbr-sidebar-pane-tab__head">
      <HrbrSidebarPaneHeader />
    </div>

    <div class="hrbr-sidebar-pane-tab__body">
      <div class="hrbr-sidebar-pane-tab__body-content">
        <HrbrSidebarPaneSection v-if="filteredMainItems.length">
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">Quick filters</div>
            </div>
          </template>
          <template v-slot:content>
            <div class="hrbr-sidebar-pane-tab__list">
              <div
                class="hrbr-sidebar-pane-tab__list-item"
                v-for="item in filteredMainItems"
                :key="item.id">
                <HrbrSidebarPaneListItem
                  :title="item.title"
                  :icon="item.icon"
                  :count="item.count"
                  :is-active="sidebarStore.dashboardPaneActive === item.id"
                  @item-click="handlePaneChange(item)" />
              </div>
            </div>
          </template>
        </HrbrSidebarPaneSection>

        <HrbrSidebarPaneSection v-if="filteredPersonalItems.length">
          <template v-slot:head>
            <div class="hrbr-sidebar-pane-tab__heading">
              <div class="hrbr-sidebar-pane-tab__heading-text">For me</div>
            </div>
          </template>
          <template v-slot:content>
            <div class="hrbr-sidebar-pane-tab__list">
              <div
                class="hrbr-sidebar-pane-tab__list-item"
                v-for="item in filteredPersonalItems"
                :key="item.id">
                <HrbrSidebarPaneListItem
                  :title="item.title"
                  :icon="item.icon"
                  :count="item.count"
                  :loading="item.loading"
                  :is-active="sidebarStore.dashboardPaneActive === item.id"
                  @item-click="handlePaneChange(item)" />
              </div>
            </div>
          </template>
        </HrbrSidebarPaneSection>
      </div>
    </div>
  </div>
</template>
