import AddAdminModal from '@/components/InternalTools/AddAdminModal.vue';
import CopyTemplateModal from '@/components/InternalTools/CopyTemplateModal.vue';
import { useHarbourStore } from '@/stores/harbour-store';
import { ModalProgrammatic as Modal } from 'buefy';
import Vue, { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router/composables';
import { useFolderActions } from '../../Sidebar/composables/use-folder-actions';

export function useCommands() {
  const harbourStore = useHarbourStore();
  const { handleAddFolderMain } = useFolderActions();

  const router = useRouter();

  // Usage of getCurrentInstance is discouraged in app code.
  // But it's necessary to support components that use buefy.
  const instance = getCurrentInstance();

  const defaultCommands = [
    {
      title: 'Upload a document',
      icon: 'file-arrow-up',
      type: 'command',
      handler: () => {
        openAgreementEditor('agreement');
      },
    },
    {
      title: 'Start from a template',
      icon: 'file-contract',
      type: 'command',
      handler: () => {
        router.push({ name: 'templates' });
      },
    },
    {
      title: 'Create a new folder',
      icon: 'folder-plus',
      type: 'command',
      handler: () => {
        handleAddFolderMain('#home');
      },
    },
  ];

  const adminCommands = [
    // TODO: Implement these feature in the next release
    // {
    //   title: 'Copy template',
    //   icon: 'copy',
    //   type: 'command',
    //   handler: () => {
    //     openAdminTemplateCopyModal();
    //   },
    // },
    {
      title: 'Add admin user',
      icon: 'user-plus',
      type: 'command',
      handler: () => {
        //TODO: refactoring org admins and relaunch
        alert('Coming soon -- after refactoring org admins!');
        //openAdminAddAdminModal();
      },
    },
  ];

  const commands = [
    {
      title: 'Create new template',
      icon: 'file-contract',
      type: 'command',
      handler: () => {
        openAgreementEditor('template');
      },
    },
    {
      title: 'Create new agreement',
      icon: 'file-arrow-up',
      type: 'command',
      handler: () => {
        openAgreementEditor('agreement');
      },
    },
    {
      title: 'View Dashboard',
      icon: 'signature',
      type: 'command',
      handler: () => {
        router.push({ name: 'dashboard' });
      },
    },
    {
      title: 'View Templates',
      icon: 'layer-plus',
      type: 'command',
      handler: () => {
        router.push({ name: 'templates' });
      },
    },
    {
      title: 'View Folders',
      icon: 'folder',
      type: 'command',
      handler: () => {
        router.push({ name: 'folders' });
      },
    },
    {
      title: 'View Settings',
      icon: 'gear',
      type: 'command',
      handler: () => {
        router.push({ name: 'settings' });
      },
    },
    {
      title: 'View Automations',
      icon: 'bolt',
      type: 'command',
      handler: () => {
        router.push({ name: 'automations' });
      },
    },
    {
      // easter egg
      title: 'Shake it off~!',
      icon: 'comment-music',
      type: 'command',
      handler: () => {
        harbourStore.isShakeHarbour = true;
        setTimeout(() => {
          harbourStore.isShakeHarbour = false;
        }, 1800);
      },
    },
  ];

  const openAgreementEditor = (creationMode = 'agreement') => {
    const props = {
      initial_workspaceCustomProperties: harbourStore.workspaceCustomProperties,
      system_email: harbourStore.contextDict.systememail,
      gae_service: harbourStore.contextDict.gae_service,
      creationMode,
      lastbrand: true,
      parent: instance.proxy,
    };
    Vue.prototype.$openAgreementEditorModal({ props });
  };

  const openAdminTemplateCopyModal = () => {
    Modal.open({
      parent: instance.proxy,
      component: CopyTemplateModal,
      hasModalCard: true,
    });
  };

  const openAdminAddAdminModal = () => {
    Modal.open({
      parent: instance.proxy,
      component: AddAdminModal,
      hasModalCard: true,
    });
  };

  return {
    commands,
    defaultCommands,
    adminCommands,
  };
}
