<script setup>
import { useDraft } from '@/composables/use-draft';
import { createDraft, DraftMainGroups, DraftTypes } from '@/domain/drafts/draft';
import { useDraftsStore } from '@/pages/Drafts/stores/drafts-store';
import { useHarbourStore } from '@/stores/harbour-store';
import { AgGridVue } from 'ag-grid-vue';
import Vue, { computed, getCurrentInstance, markRaw, reactive } from 'vue';
import { useSidebarStore } from './stores/sidebar-store';

const instance = getCurrentInstance();

const harbourStore = useHarbourStore();
const sidebarStore = useSidebarStore();
const draftsStore = useDraftsStore();

const createPlaceholderDraft = (group) => {
  const systemEmail = harbourStore.contextDict.systememail;
  const isSharedGroup = DraftMainGroups.SharedDrafts === group;

  const placeholderData = {
    id: `drafts_${Math.random().toString(36).slice(2)}_${Date.now()}`,
    name: 'Untitled',
    created_by: !isSharedGroup ? systemEmail : 'placeholder@harbourshare.com',
    is_shared: isSharedGroup,
  };
  const draft = createDraft(placeholderData);

  let reactiveDraft = useDraft(draft);
  reactiveDraft.setPath();
  reactiveDraft.setPlaceholderDraft(true);
  reactiveDraft = reactive(reactiveDraft); // to unwrap refs properties
  return reactiveDraft;
};

const draftsData = computed(() => {
  const drafts = [...draftsStore.myDrafts];
  const draftGroups = draftsStore.draftGroups;
  draftGroups.forEach((group) => {
    // create placeholder hidden drafts to display all groups
    // even if there are no drafts in group
    const isDraftFound = drafts.find((i) => i.draftPath.includes(group));
    if (!isDraftFound) {
      const placeholderDraft = createPlaceholderDraft(group);
      drafts.push(placeholderDraft);
    }
  });
  return drafts;
});

const gridOptions = {
  treeData: true,
  rowBuffer: 4,
  animateRows: true,
  suppressContextMenu: true,
  groupDefaultExpanded: 1,
  rowClassRules: {
    'ag-row--active': (params) => params.node?.key === draftsStore.activeGroup,
    'ag-row--hidden': (params) => !!params.data?.isPlaceholder,
  },
  getRowHeight: (params) => params.data?.isPlaceholder ? 1 : 36,
  postSortRows: (params) => {
    // My drafts group is always on top
    const rowNodes = params.nodes;
    for (let i = 0; i < rowNodes.length; i++) {
      const row = rowNodes[i];
      if (!row.group) continue;
      const isMyDraftsRow = row.group && row.key === 'my_drafts';
      if (isMyDraftsRow) {
        const [myDraftsRow] = rowNodes.splice(i, 1);
        rowNodes.unshift(myDraftsRow);
        break;
      }
    }
  }
};

const columnDefs = [];
const defaultColDef = {
  flex: 1,
  filter: true,
  resizable: false,
  getQuickFilterText: (params) => {
    return params.data?.name;
  },
};
const autoGroupColumnDef = {
  headerName: 'Drafts',
  cellRendererParams: {
    suppressCount: true,
    innerRenderer: 'HrbrSidebarPaneDraftsRenderer',
    nameInputBlured: (draft, newName, node) => {
      draftsStore.renameDraftWithNotify(draft.id, newName);
      draftsStore.agGridPaneApi?.redrawRows({ rowNodes: [node] });
    },
    copyLinkClicked: (draft) => {
      draftsStore.copySharedLinkWithNotify(draft);
    },
    shareDraftClicked: (draft) => {
      if (draft.type === DraftTypes.SuperDoc) {
        openSuperdocShareModal(draft);
      } else {
        openCkShareModal({
          ckFileId: draft.ckFileId,
          name: draft.name,
        });
      }
    },
    deleteClicked: (draft) => {
      draftsStore.deleteDraftWithConfirm(draft.id);
    },
  },
};

const overlayLoadingTemplate = `<div class="hrbr-sidebar-pane-drafts-menu__no-drafts">Loading drafts...</div>`;

const getRowId = (params) => params.data.id;
const getDataPath = (params) => params.draftPath;
const onGridReady = (params) => {
  draftsStore.agGridPaneApi = markRaw(params.api)
};

const getDraftGroup = (key) => {
  const group = draftsStore.draftGroups.find((i) => i === key);
  return group || null;
};

const onRowClicked = (params) => {
  const target = params.event.target;
  const isActionsClicked = !!target.closest('[data-item-actions]');
  if (isActionsClicked) return;
  const draftGroup = getDraftGroup(params.node.key);
  if (draftGroup && !params.data) handleDraftGroupClick(params);
  else handleDraftClick(params);
};

const handleDraftGroupClick = (params) => {
  const draftGroupKey = params.node.key;
  setCurrentGroup(draftGroupKey, params.node);
  setCreatorColumnVisibility(draftGroupKey);
};

const handleDraftClick = (params) => {
  const isRenamingDraft = draftsStore.isRenamingInSidebarPane;
  if (isRenamingDraft) return;

  draftsStore.openDraftDocument({
    draft: params.data,
    addUrlParams: true,
    parentComponent: instance.proxy,
  });
};

const setCurrentGroup = (groupId, selectedNode) => {
  let currentActiveRow = null;
  draftsStore.agGridPaneApi.forEachNode((node) => {
    if (node.key === draftsStore.activeGroup) currentActiveRow = node;
  });
  const rowNodes = [selectedNode];
  if (currentActiveRow) rowNodes.push(currentActiveRow);
  draftsStore.setActiveGroup(groupId);
  draftsStore.agGridPaneApi.redrawRows({ rowNodes });
};

const setCreatorColumnVisibility = (groupId) => {
  if (groupId === DraftMainGroups.SharedDrafts) {
    draftsStore.agGridColumnApi?.setColumnsVisible(['createdBy'], true);
    return;
  }
  draftsStore.agGridColumnApi?.setColumnsVisible(['createdBy'], false);
};

const openCkShareModal = ({ ckFileId, name }) => {
  Vue.prototype.$openCkeditorShareModal({
    props: {
      fileDisplayId: ckFileId,
      agreementName: name,
      parent: instance.proxy,
    },
  });
};

const openSuperdocShareModal = (draft) => {
  const collaborators = draftsStore.convertDraftCollaboratorsToSuperdoc(draft);

  const authGroups = this.harbourStore.contextDict?.auth_groups || [];
  const allUsersGroup = authGroups.find(group => group.name === "All users") || {};
  const orgUserEmails = allUsersGroup.members?.map(member => member.email) || [];

  Vue.prototype.$openSuperdocShareModal({
    props: {
      superdocId: draft.id,
      superdocTitle: draft.name,
      collaborators,
      parent: instance.proxy,
      orgUserEmails
    },
    events: {
      shared: (collaborators) => {
        draftsStore.onDocumentShared({
          id: draft.id,
          collaborators,
        });
      },
      removeCollaborator: (user) => {
        draftsStore.onRemoveCollaborator({
          id: draft.id,
          user,
        });
      },
    },
  });
};
</script>

<template>
  <div class="hrbr-sidebar-pane-drafts-menu">
    <div class="hrbr-sidebar-pane-drafts-menu__drafts-grid">
      <AgGridVue
        class="hrbr-sidebar-pane-drafts-menu__ag-grid ag-theme-alpine hrbr-ag-font-family"
        style="width: 100%; height: 100%"
        :columnDefs="columnDefs"
        :rowData="draftsData"
        :defaultColDef="defaultColDef"
        :headerHeight="40"
        :gridOptions="gridOptions"
        :autoGroupColumnDef="autoGroupColumnDef"
        :getRowId="getRowId"
        :getDataPath="getDataPath"
        @grid-ready="onGridReady"
        @row-clicked="onRowClicked"
        @filter-changed="() => {}"
        :quickFilterText="sidebarStore.filter"
        :overlayLoadingTemplate="overlayLoadingTemplate">
      </AgGridVue>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.hrbr-sidebar-pane-drafts-menu {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;

  &__drafts-grid {
    flex: 1;
    height: 100%;
  }

  &__ag-grid {
    --ag-grid-size: 3px;
    --ag-font-size: 14px;
    --ag-icon-size: 14px;
    --ag-row-height: 36px;
    --ag-row-hover-color: rgb(228 233 236 / 40%);
    --ag-data-color: #666f75;
    --ag-borders: none;
    --ag-header-foreground-color: #666f75;
    --ag-header-background-color: #e4e9ec;

    padding: 0;
    margin: 0;
  }

  :deep(&__no-drafts) {
    font-size: 11px;
    color: #666f75;
    align-self: flex-start;
  }

  :deep(.ag-header) {
    display: none;
  }

  :deep(.ag-group-value) {
    flex: 1;
  }

  :deep(.ag-row-odd) {
    background-color: transparent;
  }

  :deep(.ag-row) {
    cursor: pointer;
    border-bottom: 0;

    &--active {
      background: #E2E9FB;

      &.ag-row-hover::before {
        display: none;
      }
    }

    .hrbr-sidebar-pane-drafts-item__dropdown .dropdown-trigger {
      visibility: hidden;
    }
    &.ag-row-hover {
      .hrbr-sidebar-pane-drafts-item__dropdown .dropdown-trigger {
        visibility: visible;
      }
    }

    &--hidden {
      display: none!important;
    }
  }

  :deep(.ag-row-animation .ag-row) {
    transition: transform 0.2s, top 0.2s, background-color 0.1s, opacity 0.2s;
  }

  :deep(.hover-over) {
    background-color: #e4e9ec;
  }

  :deep(.ag-row-drag) {
    visibility: hidden;
  }

  :deep(.ag-icon.ag-icon-grip) {
    --ag-icon-font-family: 'Font Awesome 5 Pro';
    --ag-icon-font-code-grip: '\e411';
  }

  :deep(.ag-body-vertical-scroll) {
    display: none !important;
  }
}
</style>
